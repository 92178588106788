<template>
  <b-container fluid>
    <b-card no-body>
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="8"
            >
              <b-form-group
                label-cols="12"
                label-for="input-default"
              >
                <v-select
                  id="id"
                  v-model="departmentSelected"
                  placeholder="Khoa/Bộ môn"
                  label="name"
                  :options="departments"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="text-right"
            >
              <b-form-group>
                <b-button
                  variant="primary"
                  @click="btnSearchClick()"
                >
                  <feather-icon
                    icon="SearchIcon"
                    class="align-text-top"
                  />
                  Tìm kiếm
                </b-button>
              </b-form-group>
            </b-col>
            <!-- Per Page -->
          </b-row>
          <b-row>
            <b-col cols="5">
              <b-form-file
                ref="inputFileRef"
                placeholder="Chọn file để import học phần..."
                drop-placeholder="Drop file here..."
                accept=".xls, .xlsx"
                @change="importExcel"
              />
            </b-col>
            <b-col cols="7">
              <div class="text-right">
                <b-button
                  v-show="showTableImport && (isSystemAdmin() || creatable)"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="info"
                  class="ml-1"
                  @click="handleImportSubject"
                >
                  <span class="text-nowrap text-right">
                    <feather-icon icon="CheckSquareIcon" /> Import học phần
                  </span>
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="ml-1"
                  @click="downloadFile"
                >
                  <span class="text-nowrap text-right">
                    <feather-icon icon="DownloadIcon" /> Tải file mẫu
                  </span>
                </b-button>

                <b-button
                  v-if="creatable"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-modal.subjectSaveModal
                  variant="success"
                  class="ml-1"
                  @click="onCreateSubject"
                >
                  <span class="text-nowrap text-right">
                    <feather-icon icon="PlusIcon" /> Thêm học phần
                  </span>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="12">
              <div class="d-flex align-items-center mb-1 mt-0">
                <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                  đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                </span>
              </div>
              <vue-good-table
                v-show="!showTableImport"
                mode="remote"
                :columns="columns"
                :rows="subjectsDisplay"
                :pagination-options="paginationOptions"
                :total-rows="totalRows"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.column.field === 'status'">
                    {{ getStatusName(props.row.status) }}
                  </span>

                  <span v-else-if="props.column.field === 'subjectTypeId'">
                    {{ getSubjectTypeName(props.row.subjectTypeId) }}
                  </span>
                  <span v-else-if="props.column.field === 'totalCreditNum'">
                    {{ showCreditSummary(props.row) }}
                  </span>

                  <span v-else-if="props.column.field === 'action'">
                    <b-button
                      v-if="updatable"
                      v-b-modal.subjectSaveModal
                      variant="primary"
                      class="btn-icon"
                      size="sm"
                      @click="onEditSubject(props.row)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                    <b-button
                      v-if="deletable"
                      variant="danger"
                      class="btn-icon"
                      style="margin-left: 6px"
                      size="sm"
                      @click="onDeleteSubject(props.row)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </span>

                  <!-- Column: Common -->
                  <span v-else>{{
                    props.formattedRow[props.column.field]
                  }}</span>
                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                        đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                      </span>
                    </div>
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị </span>
                      <b-form-select
                        v-model="filter.itemsPerPage"
                        :options="itemsPerPageOptions"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap"> bản ghi/trang</span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="totalRows"
                        :per-page="filter.itemsPerPage"
                        class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
              <vue-good-table
                v-show="showTableImport"
                :columns="tableImport.columns"
                :rows="tableImport.data"
                :pagination-options="paginationOptions"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị 1 đến </span>
                      <b-form-select
                        v-model="filterTableExcel.itemsPerPage"
                        :options="filterTableExcel.itemsPerPageOptions"
                        class="mx-1"
                        @input="
                          (value) =>
                            props.perPageChanged({ currentPerPage: value })
                        "
                      />
                      <span
                        class="text-nowrap"
                      >của {{ props.total }} bản ghi</span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="filterTableExcel.itemsPerPage"
                        class="mt-1 mb-0"
                        @input="
                          (value) => props.pageChanged({ currentPage: value })
                        "
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
    <SubjectSave
      ref="saveModalRef"
      :item="currentSubject"
      @succeed="onSucceed"
    />
  </b-container>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormFile,
  BFormGroup,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import SubjectSave from '@/views/admin/category/subject/SubjectSave.vue'
import * as XLSX from 'xlsx'
import { Workbook } from '@syncfusion/ej2-excel-export'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { getUser } from '@/auth/utils'

export default {
  name: 'Subjects',
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BFormGroup,
    VueGoodTable,
    BFormFile,
    vSelect,
    SubjectSave,
  },
  data() {
    return {
      subjectsDisplay: [],
      departmentSelected: undefined,
      subjectTypeSelected: undefined,
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        organizationId: getUser().orgId,
        departmentId: '',
        subjectTypeId: '',
        name: '',
        code: '',
        status: 1,
        sort: '',
      },

      filterTableExcel: {
        currentPage: 1,
        itemsPerPage: 10,
        itemsPerPageOptions: [20, 30, 50, 80, 100],
      },

      currentSubject: undefined,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'STT',
          field: 'rowNum',
          width: '5%',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Mã học phần',
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          width: '15%',
          thClass: 'text-center',
        },
        {
          label: 'Tên học phần',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          width: '25%',
          thClass: 'text-center',
        },
        {
          label: 'Loại học phần',
          field: 'subjectTypeId',
          filterOptions: {
            placeholder: 'Tất cả',
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: [],
          },
          width: '20%',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            placeholder: 'Tất cả',
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: [],
          },
          width: '15%',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Tín chỉ',
          field: 'totalCreditNum',
          sortable: true,
          width: '10%',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Thao tác',
          field: 'action',
          width: '10%',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      tableImport: {
        columns: [
          {
            label: 'STT',
            field: 'rowNum',
            sortable: false,
            thClass: 'text-center',
            tdClass: 'text-right',
          },
          {
            label: 'Mã học phần',
            field: 'code',

            sortable: true,
            thClass: 'text-center',
          },
          {
            label: 'Tên học phần',
            field: 'name',
            sortable: true,
            thClass: 'text-center',
          },
          {
            label: 'Tên chính thức',
            field: 'mainName',
            sortable: true,
            thClass: 'text-center',
          },
          {
            label: 'Mã khoa',
            field: 'departmentCode',
            sortable: true,
            thClass: 'text-center',
          },
          {
            label: 'Mã loại học phần',
            field: 'subjectTypeCode',
            sortable: false,
            thClass: 'text-center',
          },
          {
            label: 'Số tín chỉ lý thuyết',
            field: 'theoryCreditNum',
            sortable: false,
            thClass: 'text-center',
          },
          {
            label: 'Số tín chỉ thực hành',
            field: 'practiceCreditNum',
            sortable: false,
            thClass: 'text-center',
          },
          {
            label: 'Số tín chỉ thực tập',
            field: 'internshipCreditNum',
            sortable: false,
            thClass: 'text-center',
          },
          {
            label: 'Tính điểm',
            field: 'countedForGpa',
            sortable: false,
            thClass: 'text-center',
          },
          {
            label: 'Số tín chỉ quy đổi',
            field: 'totalCreditNum',
            sortable: true,
            thClass: 'text-center',
            tdClass: 'text-right',
          },
        ],
        data: [],
        totalRows: 0,
      },
      showTableImport: false,
      tinChiLyThuyet: 0,
      tinChiThucHanh: 0,
    }
  },
  computed: {
    ...mapGetters({
      subjects: 'subject/subjects',
      totalRows: 'subject/totalRows',
      statuses: 'subject/statuses',
      departments: 'subject/departments',
      subjectTypes: 'subject/subjectTypes',
      systemParameters: 'parameter/dataLists',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.filterTableExcel.itemsPerPage,
      }
    },
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.SUBJECT)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.SUBJECT)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.SUBJECT)
    },
  },
  async created() {
    this.isLoading = true
    try {
      await Promise.all([
        this.begin({ organizationId: getUser().orgId }),
        this.getSystemParameters(),
      ])
      this.tinChiLyThuyet = this.systemParameters.find(item => item.code === 'LESSON_THEORY_CREDIT').value || 0
      this.tinChiThucHanh = this.systemParameters.find(item => item.code === 'LESSON_PRACTICE_CREDIT').value || 0
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      const subjectTypeCV = this.subjectTypes.map(item => ({
        value: item.id,
        text: item.name,
      }))
      const statusesCV = this.statuses.map(item => ({
        value: item.id,
        text: item.name,
      }))
      this.$set(
        this.columns[3].filterOptions,
        'filterDropdownItems',
        subjectTypeCV,
      )
      this.$set(
        this.columns[4].filterOptions,
        'filterDropdownItems',
        statusesCV,
      )
      this.isLoading = false
    }
    await this.getSubjectsFromStore()
  },
  methods: {
    isSystemAdmin,
    ...mapActions({
      begin: 'subject/begin',
      readSubjects: 'subject/readSubjects',
      updateSubjects: 'subject/updateSubjects',
      deleteSubjects: 'subject/deleteSubjects',
      importSubject: 'subject/importSubject',
      getSystemParameters: 'parameter/getAllData',
    }),
    async btnSearchClick() {
      this.isLoading = true
      if (
        this.departmentSelected !== null
        && this.departmentSelected !== undefined
      ) {
        this.filter.departmentId = this.departmentSelected.id
      } else {
        this.filter.departmentId = null
      }
      if (
        this.subjectTypeSelected !== null
        && this.subjectTypeSelected !== undefined
      ) {
        this.filter.subjectTypeId = this.subjectTypeSelected.id
      } else {
        this.filter.subjectTypeId = null
      }
      try {
        await this.getSubjectsFromStore()
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onCreateSubject() {
      this.currentSubject = undefined
    },
    onEditSubject(subject) {
      const {
        rowNum, originalIndex, vgt_id, ...rest
      } = subject
      this.currentSubject = rest
    },
    onDeleteSubject(subject) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa học phần <span class="text-danger">${subject.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteSubjects(subject.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getSubjectsFromStore()
                } catch (e) {
                  this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger')
                }
              } else {
                this.showToast(message, 'XCircleIcon', 'danger')
              }
            }
          } catch (e) {
            this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger')
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getSubjectsFromStore()
    },
    async getSubjectsFromStore() {
      this.isLoading = true
      try {
        await this.readSubjects(this.filter)
        this.subjectsDisplay = this.subjects
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.subjectsDisplay.length; i++) {
          this.subjectsDisplay[i].alterId = []
          this.subjectsDisplay[i].conditionId = []
          if (this.subjectsDisplay[i].alterIds !== null) {
            this.subjectsDisplay[i].alterId = this.subjectsDisplay[i]
              .alterIds
              .split(',')
              .map(item => +item)
          }
          if (this.subjectsDisplay[i].conditionIds !== null) {
            this.subjectsDisplay[i].conditionId = this.subjectsDisplay[i]
              .conditionIds
              .split(',')
              .map(item => +item)
          }
          if (this.subjectsDisplay[i].previousIds !== null) {
            this.subjectsDisplay[i].previousId = this.subjectsDisplay[i]
              .previousIds
              .split(',')
              .map(item => +item)
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getSubjectsFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)
        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getSubjectsFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code.trim() })
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name.trim() })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      if (Object.hasOwn(columnFilters, 'subjectTypeId')) this.updateParams({ subjectTypeId: columnFilters.subjectTypeId })
      await this.getSubjectsFromStore()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.id === id).name
    },
    getSubjectTypeName(id) {
      return this.subjectTypes.find(item => item.id === id).name
    },
    async onSucceed() {
      await this.getSubjectsFromStore()
    },

    async importExcel(e) {
      const { files } = e.target
      if (!files.length) {
        return
      } if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        // show err
      }
      if (files[0].name) {
        this.tenFile = files[0].name
      }
      const checkFileData = this.readFile(files)
      if (checkFileData && !checkFileData.status) {
        this.showToast('Có lỗi xảy ra', 'XCircleIcon', 'danger')
      }
    },
    readFile(files) {
      const res = {
        status: true,
        index: 0,
      }
      const fileReader = new FileReader()
      fileReader.onload = async ev => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, {
            type: 'binary',
          })
          const wsname = workbook.SheetNames[0]
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            header: 0,
            defval: '',
          })
          const excellist = []
          // Edit data
          if (ws.length > 0) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < ws.length; i++) {
              excellist.push(ws[i])
            }
          } else {
            this.showToast('File không có dữ liệu', 'XCircleIcon', 'danger', 3000)
            this.resetInputFile()
            return
          }
          if (
            !Object.hasOwn(excellist[0], 'MA_MON_HOC')
            || !Object.hasOwn(excellist[0], 'TEN_MON_HOC')
            || !Object.hasOwn(excellist[0], 'TEN_CHINH_THUC')
            || !Object.hasOwn(excellist[0], 'MA_KHOA')
            || !Object.hasOwn(excellist[0], 'MA_LOAI_MON_HOC')
            || !Object.hasOwn(excellist[0], 'SO_TC_LY_THUYET')
            || !Object.hasOwn(excellist[0], 'SO_TC_THUC_HANH')
            || !Object.hasOwn(excellist[0], 'SO_TC_THUC_TAP')
            || !Object.hasOwn(excellist[0], 'TINH_DIEM')
            || !Object.hasOwn(excellist[0], 'THI_LY_THUYET')
            || !Object.hasOwn(excellist[0], 'THI_THUC_HANH')
          ) {
            this.showToast('File chưa đúng định dạng, vui lòng tải file mẫu', 'XCircleIcon', 'danger', 3000)
            this.resetInputFile()
            return
          }
          this.dataExcel = []
          // eslint-disable-next-line no-plusplus
          for (let k = 0; k < excellist.length; k++) {
            const item = excellist[k]
            // eslint-disable-next-line no-bitwise
            if (!item.MA_MON_HOC || !item.TEN_MON_HOC || !item.MA_KHOA || !item.MA_LOAI_MON_HOC | !item.TEN_CHINH_THUC) {
              this.showToast(`Dữ liệu đầu vào không được trống các trường thông tin [MA_MON_HOC, TEN_MON_HOC, MA_KHOA, MA_LOAI_MON_HOC, TEN_CHINH_THUC], Kiểm tra thông tin dòng ${k + 1}`, 'XCircleIcon', 'danger', 3000)
              this.resetInputFile()
              return
            }
            let tongSoTietHoc = 0
            if (item.SO_TIET) {
              if (item.SO_TIET > 0) {
                tongSoTietHoc = item.SO_TIET
              }
            }
            tongSoTietHoc = (tongSoTietHoc > 0) ? tongSoTietHoc : parseFloat(this.tinChiThucHanh) * parseFloat(item.SO_TC_THUC_HANH || 0) + parseFloat(this.tinChiThucHanh) * parseFloat(item.SO_TC_THUC_TAP || 0) + parseFloat(this.tinChiLyThuyet) * parseFloat(item.SO_TC_LY_THUYET || 0)
            const obj = {
              rowNum: k + 1,
              code: item.MA_MON_HOC,
              name: item.TEN_MON_HOC,
              mainName: item.TEN_CHINH_THUC,
              departmentCode: item.MA_KHOA,
              subjectTypeCode: item.MA_LOAI_MON_HOC,
              theoryCreditNum: item.SO_TC_LY_THUYET,
              practiceCreditNum: item.SO_TC_THUC_HANH,
              internshipCreditNum: item.SO_TC_THUC_TAP === null || item.SO_TC_THUC_TAP.toString().trim() === '' ? 0 : item.SO_TC_THUC_TAP,
              countedForGpa: item.TINH_DIEM,
              totalCreditNum: item.SO_TC_QUY_DOI ? item.SO_TC_QUY_DOI : (parseFloat(item.SO_TC_THUC_HANH || 0) + parseFloat(item.SO_TC_THUC_TAP || 0) + parseFloat(item.SO_TC_LY_THUYET || 0)),
              // lessonNum: item.SO_TIET,
              lessonNum: tongSoTietHoc,
              // eslint-disable-next-line radix
              isTheoreticalExam: parseInt(item.THI_LY_THUYET || 0),
              // eslint-disable-next-line radix
              isPracticeExam: parseInt(item.THI_THUC_HANH || 0),
              orderNo: '',
              status: 1,
              delFlag: 0,
              createdBy: null,
            }
            this.dataExcel.push(obj)
          }
          this.showTableImport = true
          this.tableImport.data = this.dataExcel
          this.tableImport.totalRows = this.dataExcel.length
        } catch (e) {
          alert(`Read failure!${e}`)
        }
      }
      fileReader.readAsBinaryString(files[0])
      return res
    },

    resetInputFile() {
      this.$refs.inputFileRef.reset()
    },
    async handleImportSubject() {
      this.isLoading = true
      try {
        const res = await this.importSubject(this.dataExcel)
        if (res.code === 0) {
          this.showToast('Lưu thành công', 'CheckIcon', 'success')
          this.resetInputFile()
          await this.getSubjectsFromStore()
          this.showTableImport = false
        } else {
          this.showToast(res.message, 'XCircleIcon', 'danger', 3000)
          this.resetInputFile()
          this.showTableImport = false
        }
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger', 3000)
        this.resetInputFile()
        this.showTableImport = false
      } finally {
        this.isLoading = false
      }
    },

    downloadFile() {
      const columns = [
        { index: 1, width: 100 },
        { index: 2, width: 200 },
        { index: 3, width: 200 },
        { index: 4, width: 200 },
        { index: 5, width: 200 },
        { index: 6, width: 150 },
        { index: 7, width: 150 },
        { index: 8, width: 120 },
        { index: 9, width: 100 },
        { index: 10, width: 120 },
        { index: 11, width: 100 },
      ]
      const rows = []
      const headerRow = {
        index: 1,
        cells: [
          {
            index: 1,
            value: 'MA_MON_HOC',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 2,
            value: 'TEN_MON_HOC',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 3,
            value: 'TEN_CHINH_THUC',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 4,
            value: 'MA_KHOA',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 5,
            value: 'MA_LOAI_MON_HOC',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 6,
            value: 'SO_TC_LY_THUYET',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 7,
            value: 'SO_TC_THUC_HANH',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 8,
            value: 'SO_TC_THUC_TAP',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 9,
            value: 'TINH_DIEM',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 10,
            value: 'SO_TC_QUY_DOI',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 11,
            value: 'SO_TIET',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 12,
            value: 'THI_LY_THUYET',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 13,
            value: 'THI_THUC_HANH',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
        ],
      }
      const headerRow1 = {
        index: 2,
        cells: [
          {
            index: 1,
            value: '0101072',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 2,
            value: 'Đại số và hình học giải tích',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 3,
            value: 'Đại số và hình học giải tích',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 4,
            value: 'TH_SP',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 5,
            value: 'C',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 6,
            value: '2',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 7,
            value: '1',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 8,
            value: '0',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 9,
            value: '1',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 10,
            value: '3',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 11,
            value: 35,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 12,
            value: 1,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 13,
            value: 0,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
        ],
      }

      rows.push(headerRow)
      rows.push(headerRow1)
      const worksheets = [{ columns, rows }]
      const workbook = new Workbook({ worksheets }, 'xlsx')
      workbook.save('mauNhapHocPhan.xlsx')
    },

    showToast(title, icon, variant, autoHideDelay = 1000) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          autoHideDelay,
        },
      })
    },

    showCreditSummary(row) {
      return `${row.totalCreditNum} (${row.theoryCreditNum}_${row.practiceCreditNum}_${row.internshipCreditNum})`
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
